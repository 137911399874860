import React from 'react';
import '../index.css';
import Footer from '../components/Footer';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import LanguageIcon from '@material-ui/icons/Language';
import InfoIcon from '@material-ui/icons/Info';
import UpdateIcon from '@material-ui/icons/Update';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { useData } from '../contexts/Context';


const Accordion = withStyles({
    root: {
        border: '1px solid #131316',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);
  
const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#FFFFFF',
        borderBottom: '1px solid #13131622',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);


export default function Kontakt() {

    const [expanded, setExpanded] = React.useState('');
  
    const handleChange = (question) => (event, newExpanded) => {
        setExpanded(newExpanded ? question : false);
    };

    const { menuActivated } = useData();

    return (
        <div>
            {!menuActivated &&
            <div className="viewContainer">
                <div className="kontaktImage">
                    <div className="kontaktImageTitle">
                        Kontakt oss
                    </div>
                    <div className="kontaktImageLine">&nbsp;</div>
                    <div className="kontaktImageSubTitle">
                        Her kan du se hvordan du kan kontakte oss
                    </div>
                </div>
                <div className="kontaktFirstSection">
                    <div className="kontaktIntroHeader">
                        Kontaktinformasjon
                    </div>
                    <div className="kontaktIntroText">
                        Du kan kontakte oss når som helst, uansett hva det gjelder. <br/> Vi vil forsøke 
                        å svare deg så fort som mulig.
                        <div className="kontaktInformasjon">
                            <div className="kontaktEmailSymbol">
                                <EmailIcon style={{ fontSize: 24, color: "#131316" }}/>
                            </div>
                            <div>
                                cetaceaholding@gmail.com
                            </div>
                        </div>
                        <div className="kontaktInformasjon">
                            <div className="kontaktSymbol">
                                <PhoneIcon style={{ fontSize: 24, color: "#131316" }}/>
                            </div>
                            <div className="kontaktPlus">
                                +
                            </div>
                            <div>
                                47 412 09 622
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kontaktFaqSection">
                    <div className="kontaktFaqHeader">
                        Ofte stilte spørsmål
                    </div>
                    <div className="kontaktFaq">
                        <Accordion square expanded={expanded === 'question1'} onChange={handleChange('question1')}>
                            <AccordionSummary aria-controls="question1d-content" id="question1d-header">
                                <div className="kontaktAccordionQuestion">Hvilken risiko har fondet?</div>
                                {expanded === 'question1' && <CloseIcon style={{ fontSize: 24, color: "#131316" }}/>}
                                {expanded !== 'question1' && <AddIcon style={{ fontSize: 24, color: "#131316" }}/>}
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="kontaktAccordionAnswer">
                                    En utdypende risikoanalyse av fondet vil komme etterhvert. 
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'question2'} onChange={handleChange('question2')}>
                            <AccordionSummary aria-controls="question2d-content" id="question2d-header">
                                <div className="kontaktAccordionQuestion">Hvordan kan jeg ta ut penger?</div>
                                {expanded === 'question2' && <CloseIcon style={{ fontSize: 24, color: "#131316" }}/>}
                                {expanded !== 'question2' && <AddIcon style={{ fontSize: 24, color: "#131316" }}/>}
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="kontaktAccordionAnswer">
                                    Alt du trenger å gjøre er å kontakte oss og fortelle hvor mye du ønsker å ta ut. 
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'question3'} onChange={handleChange('question3')}>
                            <AccordionSummary aria-controls="question3d-content" id="question3d-header">
                                <div className="kontaktAccordionQuestion">Hvordan kan jeg investere mer?</div>
                                {expanded === 'question3' && <CloseIcon style={{ fontSize: 24, color: "#131316" }}/>}
                                {expanded !== 'question3' && <AddIcon style={{ fontSize: 24, color: "#131316" }}/>}
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="kontaktAccordionAnswer">
                                    Alt du trenger å gjøre er å kontakte oss og fortelle hvor mye du ønsker å investere.
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'question4'} onChange={handleChange('question4')}>
                            <AccordionSummary aria-controls="question4d-content" id="question4d-header">
                                <div className="kontaktAccordionQuestion">Hvor kan jeg se min avkastning?</div>
                                {expanded === 'question4' && <CloseIcon style={{ fontSize: 24, color: "#131316" }}/>}
                                {expanded !== 'question4' && <AddIcon style={{ fontSize: 24, color: "#131316" }}/>}
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="kontaktAccordionAnswer">
                                    For å se din avkastning må du logge inn på denne siden med din personlige bruker. 
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'question5'} onChange={handleChange('question5')}>
                            <AccordionSummary aria-controls="question5d-content" id="question5d-header">
                                <div className="kontaktAccordionQuestion">Hvilke verdipapirer inngår i fondet?</div>
                                {expanded === 'question5' && <CloseIcon style={{ fontSize: 24, color: "#131316" }}/>}
                                {expanded !== 'question5' && <AddIcon style={{ fontSize: 24, color: "#131316" }}/>}
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="kontaktAccordionAnswer">
                                    For øyeblikket ønsker vi ikke å oppgi verdipapirene som inngår i fondet, men
                                    dette er noe som kan endre seg senere.
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'question6'} onChange={handleChange('question6')}>
                            <AccordionSummary aria-controls="question6d-content" id="question6d-header">
                                <div className="kontaktAccordionQuestion">Hvilke skattemessige implikasjoner har fondet for meg?</div>
                                {expanded === 'question6' && <CloseIcon style={{ fontSize: 24, color: "#131316" }}/>}
                                {expanded !== 'question6' && <AddIcon style={{ fontSize: 24, color: "#131316" }}/>}
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="kontaktAccordionAnswer">
                                    For å få svar på dette kan du kontakte oss og forklare din situasjon, slik at vi
                                    kan vurdere hvilke implikasjoner dette fondet vil ha for akkurat deg. 
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
                <div className="kontaktTilbakemeldingerSection">
                    <div className="kontaktTilbakemeldingerHeader">
                        Har du noen tilbakemeldinger til oss?
                    </div>
                    <div className="kontaktTilbakemeldingerText">
                        Vi ønsker alltid å forbedre oss og setter derfor stor pris på tilbakemeldinger fra deg.
                    </div>
                    <div className="kontaktTilbakemeldingerText">
                        <div className="kontaktTilbakemelding">
                            <div className="kontaktSymbol">
                                <LanguageIcon style={{ fontSize: 24, color: "#131316" }}/>
                            </div>
                            Er det noe på nettsiden som er uoversiktelig eller som ikke fungerer?
                        </div>
                        <div className="kontaktTilbakemelding">
                            <div className="kontaktSymbol">
                                <InfoIcon style={{ fontSize: 24, color: "#131316" }}/>
                            </div>
                            Føler du at du har fått tilstrekkelig med informasjon fra oss?
                        </div>
                        <div className="kontaktTilbakemelding">
                            <div className="kontaktSymbol">
                                <UpdateIcon style={{ fontSize: 24, color: "#131316" }}/>
                            </div>
                            Skulle du ønske du fikk oppdateringer om fondet oftere?
                        </div>
                        <div className="kontaktTilbakemelding">
                            <div className="kontaktSymbol">
                                <QuestionAnswerIcon style={{ fontSize: 24, color: "#131316" }}/>
                            </div>
                            Er du fornøyd med responsen du har fått på dine henvendelser?
                        </div>
                    </div>
                </div>
                <Footer />
            </div>}
        </div>
    );
}
