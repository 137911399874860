import React, { useState, useEffect } from 'react'
import '../index.css';
import { Link } from 'react-router-dom';
import WhiteLogo from '../images/cetacea_hvit_lang.png';
import BlueLogo from '../images/cetacea_blå_lang.png';
import { useData } from '../contexts/Context';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';


export default function Header() {

    const { currentUser, menuActivated, toggleMenu, closeMenu, useMediaQuery } = useData();

    const [width] = useMediaQuery();

    const [headerClassNames, setHeaderClassNames] = useState(['defaultHeaderSection', 'defaultHeader', 'defaultTabLink'])

    const listenScrollEvent = (event) => {
        if (window.scrollY > 40) {
          return setHeaderClassNames(['otherHeaderSection', 'otherHeader', 'otherTabLink'])
        } else {
          return setHeaderClassNames(['defaultHeaderSection','defaultHeader', 'defaultTabLink'])
        } 
    }

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
      
        return () =>
          window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    if (width >= 900 && !menuActivated) {
        return (
            <div className={headerClassNames[0]}>
                <div className={headerClassNames[1]}>
    
                    {headerClassNames[1] === 'defaultHeader' &&
                    <div className='tab'>
                        <Link to="/" underline="none">
                            <img src={BlueLogo} alt="logo" className="logo" onClick={closeMenu}/>
                        </Link>
                    </div>}

                    {headerClassNames[1] === 'otherHeader' &&
                    <div className='tab'>
                        <Link to="/" underline="none">
                            <img src={WhiteLogo} alt="logo" className="logo" onClick={closeMenu}/>
                        </Link>
                    </div>}


                    {/* <div className='emptyTabLeft'>&nbsp;</div> */}

                    <div className="tabs">
                        <div className="tab">
                            <Link to="/kjop-fond" className={headerClassNames[2]}>
                                KJØP FOND
                            </Link>
                        </div>
                        <div className="tab">
                            <Link to="/avkastning" className={headerClassNames[2]}>
                                AVKASTNING
                            </Link>
                        </div>
                        <div className="tab">
                            <Link to="/om-oss" className={headerClassNames[2]}>
                                OM OSS
                            </Link>
                        </div>
                        <div className="tab">
                            <Link to="/strategi" className={headerClassNames[2]}>
                                STRATEGI
                            </Link>
                        </div>
                        <div className="tab">
                            <Link to="/kontakt" className={headerClassNames[2]}>
                                KONTAKT
                            </Link>
                        </div>
                    </div>

                    {/* <div className='emptyTabRight'>&nbsp;</div> */}
    
                    {!currentUser &&
                    <div className="cornerTab">
                        <Link to="/logg-inn" className={headerClassNames[2]}>
                            LOGG INN
                        </Link>
                    </div>}
    
                    {currentUser &&
                    <div className="cornerTab">
                        <Link to="/min-side" className={headerClassNames[2]}>
                            MIN SIDE
                        </Link>
                    </div>}
                </div>
            </div>
        );
    } else {
        return (
            <div className="otherHeaderSection">
                <div className="otherHeader">
    
                    <Link to="/" underline="none">
                        <img src={WhiteLogo} alt="logo" className="mobileLogo" onClick={closeMenu}/>
                    </Link>
    
                    {!menuActivated && 
                    <div className="menuIcon" onClick={toggleMenu}>
                        <MenuIcon style={{ fontSize: 36, color: "#FFFFFF" }} />
                    </div>}
                    
                    {menuActivated && 
                    <div className="menuIcon" onClick={toggleMenu}>
                        <CloseIcon style={{ fontSize: 36, color: "#FFFFFF" }} />
                    </div>}
    
                </div>
    
                {menuActivated &&
                <div className="menuSection">
                    <div className="menuTabs">
                        <div className="menuTab" onClick={toggleMenu}>
                            <Link to="/kjop-fond" className="menuTabLink">
                                KJØP FOND
                            </Link>
                        </div>
                        <div className="menuTab" onClick={toggleMenu}>
                            <Link to="/avkastning" className="menuTabLink">
                                AVKASTNING
                            </Link>
                        </div>
                        <div className="menuTab" onClick={toggleMenu}>
                            <Link to="/om-oss" className="menuTabLink">
                                OM OSS
                            </Link>
                        </div>
                        <div className="menuTab" onClick={toggleMenu}>
                            <Link to="/strategi" className="menuTabLink">
                                STRATEGI
                            </Link>
                        </div>
                        <div className="menuTab" onClick={toggleMenu}>
                            <Link to="/kontakt" className="menuTabLink">
                                KONTAKT
                            </Link>
                        </div>
                        {!currentUser && <div className="menuTab" onClick={toggleMenu}>
                            <Link to="/logg-inn" className="menuTabLink">
                                LOGG INN
                            </Link>
                        </div>}
                        {currentUser && <div className="menuTab" onClick={toggleMenu}>
                            <Link to="/min-side" className="menuTabLink">
                                MIN SIDE
                            </Link>
                        </div>}
                    </div>
                </div>}
            </div>
        );
    }
}