import React from 'react';
import Footer from '../components/Footer';
import '../index.css';
import { useData } from '../contexts/Context';


export default function Strategi() {

    const { menuActivated } = useData();

    return (
        <div>
            {!menuActivated &&
            <div className="viewContainer">
                <div className="strategiImage">
                    <div className="strategiImageTitle">
                        Strategi
                    </div>
                    <div className="strategiImageLine">&nbsp;</div>
                    <div className="strategiImageSubTitle">
                        Her kan du lese om vår investeringsstrategi
                    </div>
                </div>
                <div className="strategiFirstSection">
                    <div className="strategiUnderUtvikling">
                        Mer informasjon kommer.
                    </div>
                </div>
                <Footer />
            </div>}
        </div>
    );
}