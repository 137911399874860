import React, { useState, useEffect } from 'react';
import '../index.css';
import Footer from '../components/Footer';
import Admin from '../components/Admin';
import { useData } from '../contexts/Context';
import { useHistory, Link } from 'react-router-dom';
import { firestore } from '../Firebase';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PaymentIcon from '@material-ui/icons/Payment';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ErrorIcon from '@material-ui/icons/Error';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';


const Accordion = withStyles({
    root: {
        border: '1px solid #13131644',
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
    },
    expanded: {},
})(MuiAccordion);
  
const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#FFFFFF',
        borderBottom: '1px solid #13131644',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);
  
const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);


export default function MinSide() {

    const [expanded1, setExpanded1] = useState('');
    const [expanded2, setExpanded2] = useState('');
    const [expanded3, setExpanded3] = useState('');

    const [error, setError] = useState('');
    const { signOut, currentUser } = useData();
    const history = useHistory();

    async function handleSignOut() {
        setError('');

        try {
            await signOut();
            history.push('/logg-inn');
        } catch {
            setError('Noe gikk galt, prøv igjen')
        }
    }



    // get data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [userType, setUserType] = useState('none');  // 'admin', 'user' or 'newUser'

    const getData = async() => {
        setLoading(true);
        const master = (await firestore.collection('master').doc('master').get()).data();
        if (master.admins.includes(currentUser.uid)) {
            setUserType('admin');
        } 
        else {
            const user = (await firestore.collection('users').doc(currentUser.uid).get()).data();
            if (user) {
                const alleInnskudd = []
                for (const innskuddId in user.innskudd) {
                    const id = user.innskudd[innskuddId];
                    const innskudd = (await firestore.collection('innskudd').doc(id).get()).data();
                    alleInnskudd.push(innskudd)
                }
                const dataItems = {
                    name: user.name,
                    date: master.date,
                    verdi: master.verdi,
                    eierandel: user.eierandel,
                    beholdning: Math.round(master.verdi * user.eierandel),
                    totaleInnskudd: user.totaleInnskudd,
                    pengerTjent: Math.round(master.verdi * user.eierandel) - user.totaleInnskudd,
                    innskudd: alleInnskudd,
                    avkastning: ((((master.verdi * user.eierandel) - user.totaleInnskudd) / user.totaleInnskudd) * 100).toFixed(2)
                }
                setData(dataItems);
                setUserType('user');
            } 
            else {
                setUserType('newUser');
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleChange = (question) => (event) => {
        if (question === "1") {
            setExpanded1(expanded1 ? false : true);
        }
        else if (question === "2") {
            setExpanded2(expanded2 ? false : true);
        }
        else {
            setExpanded3(expanded3 ? false : true);
        }
    }

    const { menuActivated } = useData();


    return (
        <div>
            {!menuActivated &&
            <div className="viewContainer">

                {/* Loading page */}
                {loading && <div className="minSideLoadingPage">&nbsp;</div>}

                {/* Admin */}
                {!loading && userType === 'admin' && <Admin />}

                {/* Vanlige brukere */}
                {!loading && userType === 'user' && 
                <div className="minSideSection">
                    <div className="minSideWelcome">
                        Hei, {data.name}
                    </div>
                    <div className="minSideBeholdningRow">
                        <div className="minSideBeholdningColumn">
                            <div className="minSideInnskuddSymbol">
                                <PaymentIcon style={{ fontSize: 50, color: "#FFFFFF" }}/>
                            </div>
                            <div className="minSideInnskuddHeader">
                                Dine transaksjoner
                            </div>
                            <div className="minSideBeholdningColumnContent">
                                <Accordion square expanded={expanded1} onChange={handleChange("1")}>
                                    <AccordionSummary>
                                        <div className="minSideQuestion">
                                            <div className="minSideQuestionDesc">
                                                Totalt innskudd:
                                            </div>
                                            <div className="minSideQuestionValue">
                                                {data.totaleInnskudd},-
                                            </div>
                                        </div>
                                        {expanded1 && <ExpandLessIcon style={{ fontSize: 24, color: "#131316" }}/>}
                                        {!expanded1 && <ExpandMoreIcon style={{ fontSize: 24, color: "#131316" }}/>}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="minSideInnskuddAnswer">
                                            Transaksjonshistorikk:
                                            {data.innskudd && data.innskudd.map(innskudd => {
                                            return (
                                                <div className="minSideInnskuddRow">
                                                    <div className="minSideInnskuddDate">
                                                        {innskudd.date}:
                                                    </div>
                                                    <div className="minSideInnskuddAmount">
                                                        {innskudd.amount},-
                                                    </div>
                                                    <div className="minSideInnskuddType">
                                                        {innskudd.type}
                                                    </div>
                                                </div>
                                            )})}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="minSideBeholdningColumnDate">
                                Sist oppdatert: {data.date}
                            </div>
                        </div>
                        <div className="minSideBeholdningColumn">
                            <div className="minSideBeholdningSymbol">
                                <AccountBalanceIcon style={{ fontSize: 50, color: "#FFFFFF" }}/>
                            </div>
                            <div className="minSideBeholdningHeader">
                                Din beholdning
                            </div>
                            <div className="minSideBeholdningColumnContent">
                                <Accordion square expanded={expanded2} onChange={handleChange("2")}>
                                    <AccordionSummary>
                                        <div className="minSideQuestion">
                                            <div className="minSideQuestionDesc">
                                                Total beholdning:
                                            </div>
                                            <div className="minSideQuestionValue">
                                                {data.beholdning},-
                                            </div>
                                        </div>
                                        {expanded2 && <ExpandLessIcon style={{ fontSize: 24, color: "#131316" }}/>}
                                        {!expanded2 && <ExpandMoreIcon style={{ fontSize: 24, color: "#131316" }}/>}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {data.avkastning >= 0 &&
                                        <div className="minSideAnswer">
                                            Dette betyr at du har tjent 
                                            totalt {data.pengerTjent} NOK 
                                            på dine investeringer i fondet. 
                                        </div>}
                                        {data.avkastning < 0 &&
                                        <div className="minSideAnswer">
                                            Dette betyr dessverre at du har tapt 
                                            totalt {-1 * data.pengerTjent} NOK 
                                            på dine investeringer i fondet. 
                                            Det finnes ingen garantier i markedet, 
                                            men vi har stor tro på at dette vil snu. 
                                        </div>}
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="minSideBeholdningColumnDate">
                                Sist oppdatert: {data.date}
                            </div>
                        </div>
                        <div className="minSideBeholdningColumn">
                            <div className="minSideAvkastningSymbol">
                                <TrendingUpIcon style={{ fontSize: 50, color: "#FFFFFF" }}/>
                            </div>
                            <div className="minSideAvkastningHeader">
                                Din avkastning
                            </div>
                            <div className="minSideBeholdningColumnContent">
                                <Accordion square expanded={expanded3} onChange={handleChange("3")}>
                                    <AccordionSummary>
                                        <div className="minSideQuestion">
                                            <div className="minSideQuestionDesc">
                                                Total avkastning:
                                            </div>
                                            {data.avkastning >= 0 &&
                                            <div className="minSideQuestionReturn">
                                                <ArrowDropUpIcon style={{ fontSize: 40, color: "#11dd0a", marginTop: -8, marginRight: -8 }} />
                                                <div style={{ fontWeight: 600, color: "#11dd0a" }}>
                                                    {data.avkastning}%
                                                </div>
                                            </div>}
                                            {data.avkastning < 0 &&
                                            <div className="minSideQuestionReturn">
                                                <ArrowDropDownIcon style={{ fontSize: 40, color: "#c91414", marginTop: -8, marginRight: -8 }} />
                                                <div style={{ fontWeight: 600, color: "#c91414" }}>
                                                    {data.avkastning}%
                                                </div>
                                            </div>}
                                        </div>
                                        {expanded3 && <ExpandLessIcon style={{ fontSize: 24, color: "#131316" }}/>}
                                        {!expanded3 && <ExpandMoreIcon style={{ fontSize: 24, color: "#131316" }}/>}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="minSideAnswer">
                                            Ytterligere informasjon om din avkastning vil dukke opp her etterhvert. 
                                            Dette vil blant annet inkludere en visualisering av din avkastning og en 
                                            sammenligning med Oslo Børs og andre eventuelle referanseindekser.
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="minSideBeholdningColumnDate">
                                Sist oppdatert: {data.date}
                            </div>
                        </div>
                    </div>

                    {error && <div className="danger">
                        <div className="dangerSymbol">
                            <ErrorIcon style={{ fontSize: 30, color: "#FFFFFF" }}/>
                        </div>
                        {error}
                    </div>}
                    {!error && <div className="empty">&nbsp;</div>}

                    <div className="signOutButtonSection">
                        <button className="signOut" onClick={handleSignOut}>Logg ut</button>
                        <div className="signOutSymbol">
                            <ExitToAppIcon style={{ fontSize: 30, color: "#FFFFFF" }}/>
                        </div>
                    </div>
                </div>}

                {/* Brukere som ikke er registrert */}
                {!loading && userType === 'newUser' &&
                <div className="minSideSection">
                    <div className="minSideWelcome">
                        Hei
                    </div>
                    <div className="minSideSubWelcome">
                        Vi har ikke registrert noen innskudd på deg inne på denne siden. 
                    </div>
                    <div className="minSideSubWelcome">
                        Dette skyldes én av to årsaker:
                    </div> 
                    <div className="minSideReason">
                        <div className="minSideReasonSymbol">
                            <PaymentIcon style={{ fontSize: 32, color: "#131316" }}/>
                        </div>
                        <div className="minSideReasonTitle">
                            Du har ikke gjort noen innskudd. 
                        </div>
                    </div>
                    <div className="minSideReasonText">
                        Dersom dette er tilfellet, og dersom du ønsker
                        å kjøpe fond i Cetacea, trenger du bare å&nbsp;
                        <Link to='/kontakt' className="minSideKontaktLink">kontakte oss</Link>
                        &nbsp;og fortelle hvor mye du ønsker å kjøpe. Deretter vil vi kontakte 
                        deg tilbake med ytterligere informasjon. 
                    </div>
                    <div className="minSideReason">
                        <div className="minSideReasonSymbol">
                            <AccessTimeIcon style={{ fontSize: 32, color: "#131316" }}/>
                        </div>
                        <div className="minSideReasonTitle">
                            Du har nylig registrert denne brukeren.
                        </div>
                    </div>
                    <div className="minSideReasonText">
                        Dersom dette er tilfellet vil alle 
                        innskudd du har gjort, din nåværende beholdning og din nåværende avkastning vises
                        så snart vi har fått registrert din bruker i våre databaser. Dette tar normalt
                        ikke lenger enn én virkedag, og dersom det har gått mer tid enn dette kan du&nbsp;
                        <Link to='/kontakt' className="minSideKontaktLink">kontakte oss</Link> slik at vi kan
                        rette opp i en eventuell feil som har skjedd. 
                    </div>

                    {error && <div className="danger">
                        <div className="dangerSymbol">
                            <ErrorIcon style={{ fontSize: 30, color: "#FFFFFF" }}/>
                        </div>
                        {error}
                    </div>}
                    {!error && <div className="empty">&nbsp;</div>}

                    <div className="signOutButtonSection">
                        <button className="signOut" onClick={handleSignOut}>Logg ut</button>
                        <div className="signOutSymbol">
                            <ExitToAppIcon style={{ fontSize: 30, color: "#FFFFFF" }}/>
                        </div>
                    </div>

                </div>}
                {!loading && <Footer />}
            </div>}
        </div>
    );
}
