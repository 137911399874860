import React, { useRef, useState } from 'react';
import '../index.css';
import { useData } from '../contexts/Context';
import { useHistory } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReplayIcon from '@material-ui/icons/Replay';

export default function SignIn() {

    const [view, setView] = useState('signIn');  // signIn, signUp or resetPassword

    const emailRef = useRef('');
    const passwordRef = useRef('');
    const passwordConfirmationRef = useRef('');
    
    const { signIn, signUp, resetPassword } = useData();
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    
    function onViewChange(newView) {
        setView(newView);
    }

    async function handleSignIn(event) {
        setError('');
        setMessage('');
        event.preventDefault();

        try {
            setError('');
            setLoading(true);
            await signIn(emailRef.current.value, passwordRef.current.value);
            history.push('/min-side');
        } catch {
            setError('Noe gikk galt, prøv igjen')
        }

        setLoading(false);
    }

    async function handleSignUp(event) {
        setError('');
        event.preventDefault();

        if (passwordRef.current.value !== passwordConfirmationRef.current.value) {
            return setError('Passordene er ikke like');
        }

        if (passwordRef.current.value.length < 6) {
            return setError('Passordet må ha minst 6 tegn');
        }

        try {
            setError('');
            setLoading(true);
            await signUp(emailRef.current.value, passwordRef.current.value);
            history.push('/minSide');
        } catch {
            setError('Noe gikk galt, prøv igjen')
        }

        setLoading(false);
    }

    async function handleResetPassword(event) {
        setError('');
        setMessage('');
        event.preventDefault();

        try {
            setMessage('');
            setError('');
            setLoading(true);
            await resetPassword(emailRef.current.value);
            setMessage('Sjekk innboksen din');
        } catch {
            setError('Noe gikk galt, prøv igjen')
        }

        setLoading(false);
    }

    return (
        <div className="signInContainer">
            {view === "signIn" &&
            <div className="signInBox">

                <div className="signInTitle">
                    LOGG INN
                </div>

                {error && 
                <div className="danger">
                    <div className="errorIcon">
                        <ErrorIcon style={{ fontSize: 30, color: "#ffffff" }}/>
                    </div>
                    {error}
                </div>}

                {!error && <div className="emptyBox">&nbsp;</div>}

                <form className="signInForm" onSubmit={handleSignIn}>

                    E-post
                    <input id="email" className="signInField" type="email" ref={emailRef} required />

                    Passord
                    <input id="password" className="signInField" type="password" ref={passwordRef} required />

                    <div className="signInButton">
                        <input disabled={loading} className="signInSubmit" type="submit" value="Logg inn" />
                        <div className="signInIcon">
                            <ArrowRightAltIcon style={{ fontSize: 36, color: "#ffffff" }}/>
                        </div>
                    </div>
                </form>

                <div className="signInEllerLineSection">
                    <div className="signInEllerLine">&nbsp;</div>
                    <div className="signInEller">eller</div>
                    <div className="signInEllerLine">&nbsp;</div>
                </div>

                <div className="newUserButton" onClick={() => onViewChange('signUp')}>
                    <div className='newUserButtonText'>
                        Registrer ny bruker
                    </div>
                    <div className="newUserIcon">
                        <PersonAddIcon style={{ fontSize: 32, color: "#ffffff" }}/>
                    </div>
                </div>

                <div className="signInLink" onClick={(i) => onViewChange('resetPassword')}>
                    Glemt passord?
                </div>

            </div>}

            {view === "signUp" &&
            <div className="signInBox">

                <div className="signInTitle">
                    REGISTRER NY BRUKER
                </div>

                {error && 
                <div className="danger">
                    <div className="errorIcon">
                        <ErrorIcon style={{ fontSize: 30, color: "#ffffff" }}/>
                    </div>
                    {error}
                </div>}

                {!error && <div className="emptyBox">&nbsp;</div>}

                <form className="signInForm" onSubmit={handleSignIn}>

                    E-post
                    <input id="email" className="signInField" type="email" ref={emailRef} required />

                    Passord
                    <input id="password" className="signInField" type="password" ref={passwordRef} required />

                    Bekreft passord
                    <input id="password" className="signInField" type="password" ref={passwordRef} required />

                    <div className="signInButton">
                        <input disabled={loading} className="signInSubmit" type="submit" value="Registrer ny bruker" />
                        <div className="signInIcon">
                            <ArrowRightAltIcon style={{ fontSize: 36, color: "#ffffff" }}/>
                        </div>
                    </div>
                </form>

                <div className="signInLink" onClick={(i) => onViewChange('signIn')}>
                    Logg inn
                </div>

            </div>}

            {view === "resetPassword" &&
            <div className="signInBox">

                <div className="signInTitle">
                    TILBAKESTILL PASSORD
                </div>

                <div className="signInSpace">&nbsp;</div>

                {error && 
                <div className="danger">
                    <div className="errorIcon">
                        <ErrorIcon style={{ fontSize: 30, color: "#ffffff" }}/>
                    </div>
                    {error}
                </div>}

                {message && 
                <div className="success">
                    <div className="errorIcon">
                        <CheckCircleIcon style={{ fontSize: 30, color: "#ffffff" }}/>
                    </div>
                    {message}
                </div>}

                {!(error || message) && <div className="emptyBox">&nbsp;</div>}

                <div className="signInSpace">&nbsp;</div>

                <form className="signInForm" onSubmit={handleResetPassword}>

                    E-post
                    <input id="email" className="signInField" type="email" ref={emailRef} required />

                    <div className="resetPasswordButton">
                        <input disabled={loading} className="signInSubmit" type="submit" value="Tilbakestill passordet" />
                        <div className="resetPasswordIcon">
                            <ReplayIcon style={{ fontSize: 27, color: "#ffffff" }}/>
                        </div>
                    </div>
                    
                </form>

                <div className="signInResetPasswordSpace">&nbsp;</div>

                <div className="signInLink" onClick={(i) => onViewChange('signIn')}>
                    Logg inn
                </div>

            </div>}
        </div>
    );
}
