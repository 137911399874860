import React  from 'react';
import '../index.css';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import SvgHomeBackground1 from '../svg/SvgHomeBackground1';
import SvgHomeBackground2 from '../svg/SvgHomeBackground2';
import InfoIcon from '@material-ui/icons/Info';
import { useData } from '../contexts/Context';


export default function Home() {

    const { menuActivated, useMediaQuery } = useData();
    const [width] = useMediaQuery();

    return (
        <div>
            {!menuActivated &&
            <div className="viewContainer">
                <div className="homeImage">
                    {width > 650 && <div className="homeImageTitle">
                        Velkommen til Cetacea
                    </div>}
                    {width <= 650 && <div className="homeImageTitle">
                        Velkommen
                    </div>}
                    <div className="homeImageLine">&nbsp;</div>
                    <div className="homeImageSubTitle">
                        Vi er et nyoppstartet fond med et mål om at du skal tjene penger
                    </div>
                    <div className="homeImageButtons">
                        <div className="homeImageButtonContainer">
                            <Link to="/kjop-fond" underline="none">
                                <button className="homeImageButton">Kjøp fond</button>
                            </Link>
                        </div>
                        <div className="homeImageButtonConatainer">
                            <Link to="/logg-inn" underline="none">
                                <button className="homeImageButton">Logg inn</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="homeSection">

                    {/* <div className="homeSectionBackground">
                        <SvgHomeBackground1 />
                    </div> */}

                    {/* <div className="homeUnderUtvikling">
                        <div className="homeInfoSymbol">
                            <InfoIcon style={{ fontSize: 32, color: "#000000cb" }}/>
                        </div>
                        Denne nettsiden er fortsatt under utvikling og vil oppdateres kontinuerlig. 
                        Vi håper du finner informasjonen du er ute etter, og dersom du ikke gjør det
                        setter vi stor pris på en tilbakemelding. 
                    </div> */}

                    <div className='homeFirstSection'>
                        <div className='homeFirstSectionTitle'>
                            Hva er Cetacea?
                        </div>
                        <div className='homeFirstSectionLine'>&nbsp;</div>
                        <div className="homeFirstSectionText">
                            Cetacea AS er et norsk holdingselskap stiftet 16. september 2020 som 
                            forvalter et fond under samme navn. Et fond er en kollektiv 
                            investering der flere sparere kan gå sammen om å plassere 
                            pengene sine i verdipapirmarkedet. Vår jobb er å 
                            investere dine penger for deg, slik at du kan spare tid uten å 
                            gå glipp av mulighetene som finnes i markedet.
                        </div>
                        <div className="homeButtonDiv">
                            <Link to="/om-oss" underline="none">
                                <button className="homeButton">Les mer om oss</button>
                            </Link>
                        </div>
                    </div>

                    <div className='homeSecondSection'>
                        <div className="homeCard">
                            <div className="homeFirstImage">&nbsp;</div>
                            <div className="homeBox">
                                <div className="homeBoxHeader">
                                    Hvorfor Cetacea?
                                </div>
                                <div className="homeBoxText">
                                    Som kunde hos Cetacea kan du være trygg på at vi gjør alt for
                                    å gi deg meravkastning, og en ekstra trygghet for deg 
                                    er at alle forvalterne har gått inn med privat kapital i fondet. 
                                    Det betyr at vi forvalter dine penger akkurat slik vi forvalter våre egne.
                                    Vår ambisjon er å oppnå høyere avkastning enn Oslo Børs, noe vi
                                    så langt har levd opp til grunnet vår strategi.   
                                </div>
                                <div className="homeCardButtonDiv">
                                    <Link to="/avkastning" underline="none">
                                        <button className="homeButton">Les om fondets avkastning</button>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="homeCard">
                            {width < 900 && <div className="homeSecondImage">&nbsp;</div>}
                            <div className="homeBox">
                                <div className="homeBoxHeader">
                                    Hvordan gjør fondet det?
                                </div>
                                <div className="homeBoxText">
                                    Vi er et relativt nytt fond og har derfor ingen historisk avkastning 
                                    over lengre tid å vise til. Den avkastningen vi har oppnådd så langt, 
                                    derimot, er noe vi er stolte av å kunne vise til. Du kan finne en oversikt
                                    over vår historiske avkastning siden fondets oppstart ved å trykke på knappen under. 
                                </div>
                                <div className="homeCardButtonDiv">
                                    <Link to="/strategi" underline="none">
                                        <button className="homeButton">Les om vår strategi</button>
                                    </Link>
                                </div>
                            </div>
                            {width >= 900 && <div className="homeSecondImage">&nbsp;</div>}
                        </div>
                    </div>

                    <div className='homeFirstSection'>
                        <div className='homeFirstSectionTitle'>
                            Lurer du på noe?
                        </div>
                        <div className='homeFirstSectionLine'>&nbsp;</div>
                        <div className="homeFirstSectionText">
                            Dersom du har noen spørsmål knyttet til Cetacea, eller dersom du lurer på noe
                            helt annet, er det bare å kontakte oss når som helst. Vi forsøker alltid å svare  
                            på alle henvendelser så fort og presist som mulig. Dersom vi ikke er i stand til
                            å svare umiddelbart, vil vi bruke tid på å sette oss inn i 
                            situasjonen, lese oss opp og finne relevant informasjon. 
                        </div>
                        <div className="homeButtonDiv">
                            <Link to="/kontakt" underline="none">
                                <button className="homeButton">Kontakt oss</button>
                            </Link>
                        </div>
                    </div>

                </div>

                <Footer />
            </div>}
        </div>
    );
}