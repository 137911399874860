import React, {Component} from 'react';
import '../index.css';
import Header from './Header';
import Home from '../views/Home';
import OmOss from '../views/OmOss';
import Kontakt from '../views/Kontakt';
import LoggInn from '../views/LoggInn';
import ScrollToTop from './ScrollToTop';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import { ContextProvider } from '../contexts/Context';
import MinSide from '../views/MinSide';
import PrivateRoute from './PrivateRoute';
import KjopFond from '../views/KjopFond';
import Avkastning from '../views/Avkastning';
import Strategi from '../views/Strategi';

export default class App extends Component {

    render() {
        return (
            <div>
                <Router>
                    <ContextProvider>
                        <ScrollToTop>
                            <Header />
                            <Switch>
                                <Route path="/kjop-fond" component={KjopFond} />
                                <Route path="/avkastning" component={Avkastning} />
                                <Route path="/om-oss" component={OmOss} />
                                <Route path="/strategi" component={Strategi} />
                                <Route path="/kontakt" component={Kontakt} />
                                <Route path="/logg-inn" component={LoggInn} />
                                <PrivateRoute path="/min-side" component={MinSide} />
                                <Route path="/" component={Home} />
                            </Switch>
                        </ScrollToTop>
                    </ContextProvider>
                </Router>
            </div>
        );
    }
}
