import React from 'react';
import '../index.css';

export default function SvgKjopFondDown(props) {

    const color1 = props.color1;
    const color2 = props.color2;

    return (
        <div className="kjopFondSvg">
            <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">

                <rect x="0" y="0" width="100" height="100" stroke="none" fill={color1} />

                <path d="M 0 0 L 50 100 L 100 0 Z" stroke="none" fill={color2} />

            </svg>
        </div>
    );
}
