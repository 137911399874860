import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import '../index.css';
import { Link } from 'react-router-dom';
import { useData } from '../contexts/Context';
import { firestore } from '../Firebase';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SvgGraf from '../svg/SvgGraf';


export default function Avkastning() {

    const { menuActivated } = useData();

    const [returns, setReturns] = useState({' ': null, '  ': null, '   ': null, '    ': null, '     ': null});

    const getData = async() => {
        var kursHistory = {};  // {n_days_ago: kurs_value}

        var today = new Date();

        const kursHistoryData = await firestore.collection('kurs').get();
        kursHistoryData.docs.forEach( async(singleKurs) => {
            let dateArray = singleKurs.id.split(".");
            let day = dateArray[0];
            let month = dateArray[1] - 1;
            let year = dateArray[2];
            let date = new Date(year, month, day);

            let daysAgo = getDaysBetweenDates(today, date);

            let kursValue = singleKurs.data().value;
            kursHistory[daysAgo] = kursValue;
        })

        var allDays = Object.keys(kursHistory);
        allDays.sort(function(a, b) {
            return a - b;
        });

        const firstDay = new Date(today.getFullYear(), 0, 1);
        const daysThisYear = getDaysBetweenDates(today, firstDay);

        var targetDays = [];
        var daysThisYearPosition = 0;
        if (daysThisYear < 30) {
            targetDays = [daysThisYear, 30, 91, 365];
        } else if (daysThisYear < 91) {
            targetDays = [30, daysThisYear, 91, 365];
            daysThisYearPosition = 1;
        } else {
            targetDays = [30, 91, daysThisYear, 365];
            daysThisYearPosition = 2;
        }

        var allReturns = [0, 0, 0, 0];

        var currentLow = allDays[0];
        var currentReturn = 0;

        for (let daysAgo of allDays) {
            if (currentReturn < 4) {
                if (daysAgo < targetDays[currentReturn]) {
                    currentLow = daysAgo;
                } else if (daysAgo === targetDays[currentReturn]) {
                    allReturns[currentReturn] = kursHistory[daysAgo];
                    currentReturn++;
                } else {
                    var lowKurs = kursHistory[currentLow];
                    var lowOffset = targetDays[currentReturn] - currentLow;

                    var highKurs = kursHistory[daysAgo];
                    var highOffset = daysAgo - targetDays[currentReturn];

                    var kursDifference = highKurs - lowKurs;
                    var kursOffset = kursDifference * (lowOffset / (lowOffset + highOffset));
                    var kursEstimate = lowKurs + kursOffset;

                    allReturns[currentReturn] = kursEstimate;
                    currentReturn++;
                }
            }
        }

        // CAN REMOVE ON DECEMBER 1st
        if (allReturns[3] === 0) {
            allReturns[3] = 10000;
        }

        const kursToday = kursHistory[allDays[0]];

        const finalReturns = [
            (((kursToday - allReturns[0]) / allReturns[0]) * 100).toFixed(2),
            (((kursToday - allReturns[1]) / allReturns[1]) * 100).toFixed(2),
            (((kursToday - allReturns[2]) / allReturns[2]) * 100).toFixed(2),
            (((kursToday - allReturns[3]) / allReturns[3]) * 100).toFixed(2),
            (((kursToday - 10000) / 10000) * 100).toFixed(2)
        ]

        var allReturnPeriods = [
            "Siste måned",
            "Siste 3 mnd",
            "Siste 12 mnd",
            "Siden 30.11.2020" 
        ];
        allReturnPeriods.splice(daysThisYearPosition, 0, "Hittil i år");

        var returns = {};

        allReturnPeriods.forEach((key, i) => returns[key] = finalReturns[i]);

        setReturns(returns);
    }

    function getDaysBetweenDates(date1, date2) {
        let difference = date1.getTime() - date2.getTime();
        let differenceInDays = Math.floor(difference / (1000 * 3600 * 24));
        return differenceInDays;
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            {!menuActivated && 
            <div className="viewContainer">

                <div className="avkastningImage">
                    {/* <div className="avkastningImageBackground">&nbsp;</div> */}
                    <div className="avkastningImageTitle">
                        Avkastning
                    </div>
                    <div className="avkastningImageLine">&nbsp;</div>
                    <div className="avkastningImageSubTitle">
                        Her kan du se vår historiske avkastning
                    </div>
                </div>

                <div className="avkastningFirstSection">
                    <div className="avkastningTable">
                        {Object.keys(returns).map((key, i) => {
                        return (
                            <div className="avkastningColumn" key={i}>  

                                <div className="avkastningText">
                                    {key}
                                </div>

                                {returns[key] >= 0 &&
                                <div className="avkastningValue">
                                    <ArrowDropUpIcon style={{ fontSize: 44, color: "#07ca00", marginTop: -10, marginRight: -6, marginLeft: -12 }} />
                                    <div style={{ fontSize: 20, fontWeight: 600, color: "#07ca00" }}>
                                        {returns[key]}%
                                    </div>
                                </div>}

                                {returns[key] < 0 &&
                                <div className="avkastningValue">
                                    <ArrowDropDownIcon style={{ fontSize: 44, color: "#c91414", marginTop: -10, marginRight: -6, marginLeft: -12 }} />
                                    <div style={{ fontSize: 20, fontWeight: 600, color: "#c91414" }}>
                                        {returns[key]}%
                                    </div>
                                </div>}

                            </div>
                        )})}
                    </div>

                    <div className="avkastningLoggInnText">
                        Dersom du er kunde hos Cetacea kan
                        du <Link to='/logg-inn' className="avkastningLoggInnLink">logge inn</Link> for 
                        å se din personlige avkastning i fondet.
                    </div>
                </div>

                {/* <div className="avkastningsGraf">
                    <SvgGraf />
                </div> */}

                <Footer />
            </div>}
        </div>
    );
}