import React, { useState, useEffect } from 'react';
import '../index.css';
import { useData } from '../contexts/Context';
import { useHistory } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { firestore } from '../Firebase';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import BackupIcon from '@material-ui/icons/Backup';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import WarningIcon from '@material-ui/icons/Warning';


export default function Admin() {

    // La eier-tabellen ha fixed høyde slik at tabell-komponenten scrolles
    // Sortering fra databasen


    // alle inputs: 16 stykk
    // --> value, beholdning før, beholdning etter, dato, user1, amount1, type1,
    //     user2, amount2, type2, user3, amount3, type3, user4, amount4, type 4

    const [valueInput, setValueInput] = useState('');
    const handleValueChange = (e) => {setValueInput(e.target.value);}

    const [beholdningFørInput, setBeholdningFørInput] = useState('');
    const handleBeholdningFørChange = (e) => {setBeholdningFørInput(e.target.value);}

    const [beholdningEtterInput, setBeholdningEtterInput] = useState('');
    const handleBeholdningEtterChange = (e) => {setBeholdningEtterInput(e.target.value);}

    const [dateInput, setDateInput] = useState('');
    const handleDateChange = (e) => {setDateInput(e.target.value);}

    const [user1Input, setUser1Input] = useState('');
    const handleUser1Change = (e) => {setUser1Input(e.target.value);}

    const [amount1Input, setAmount1Input] = useState('');
    const handleAmount1Change = (e) => {setAmount1Input(e.target.value);}

    const [type1Input, setType1Input] = useState('innskudd');
    const handleType1Change = (e) => {setType1Input(e.target.value);}

    const [user2Input, setUser2Input] = useState('');
    const handleUser2Change = (e) => {setUser2Input(e.target.value);}

    const [amount2Input, setAmount2Input] = useState('');
    const handleAmount2Change = (e) => {setAmount2Input(e.target.value);}

    const [type2Input, setType2Input] = useState('innskudd');
    const handleType2Change = (e) => {setType2Input(e.target.value);}

    const [user3Input, setUser3Input] = useState('');
    const handleUser3Change = (e) => {setUser3Input(e.target.value);}

    const [amount3Input, setAmount3Input] = useState('');
    const handleAmount3Change = (e) => {setAmount3Input(e.target.value);}

    const [type3Input, setType3Input] = useState('innskudd');
    const handleType3Change = (e) => {setType3Input(e.target.value);}

    const [user4Input, setUser4Input] = useState('');
    const handleUser4Change = (e) => {setUser4Input(e.target.value);}

    const [amount4Input, setAmount4Input] = useState('');
    const handleAmount4Change = (e) => {setAmount4Input(e.target.value);}

    const [type4Input, setType4Input] = useState('innskudd');
    const handleType4Change = (e) => {setType4Input(e.target.value);}

    const clearInputs = () => {
        setValueInput('');
        setBeholdningFørInput('');
        setBeholdningEtterInput('');
        setDateInput('');
        setUser1Input('');
        setAmount1Input('');
        setType1Input('innskudd');
        setUser2Input('');
        setAmount2Input('');
        setType2Input('innskudd');
        setUser3Input('');
        setAmount3Input('');
        setType3Input('innskudd');
        setUser4Input('');
        setAmount4Input('');
        setType4Input('innskudd');
    }


    
    // tabs
    const [kapitalActive, setKapitalActive] = useState(true);
    const [eierandelerActive, setEierandelerActive] = useState(false);
    const [eierandelerSum, setEierandelerSum] = useState(0);
    // const [honorarActive, setHonorarActive] = useState(false);
    const [innskuddActive, setInnskuddActive] = useState(false);
    const [backupActive, setBackupActive] = useState(false);
    const [highlightColors, setHighlightColors] = useState(["#d11b67", "#131316", "#131316", "#131316"]);
    const [highlightColor, setHighlightColor] = useState(["#d11b67"]);

    function handleNavigationKapital() {
        setInnskuddActive(false);
        setEierandelerActive(false);
        // setHonorarActive(false);
        setBackupActive(false);
        setKapitalActive(true);
        setHighlightColors(["#d11b67", "#131316", "#131316", "#131316"]);
        setHighlightColor("#d11b67");

        setBackupExpanded(0);
        setMessage("");
    }
    function handleNavigationEierandeler() {
        setKapitalActive(false);
        setInnskuddActive(false);
        setBackupActive(false);
        // setHonorarActive(false);
        setEierandelerActive(true);
        setHighlightColors(["#131316", "#19d46d", "#131316", "#131316"]);
        setHighlightColor("#19d46d");

        setBackupExpanded(0);
        setMessage("Summen av alle eierandeler: " + eierandelerSum.toFixed(12));
    }
    // function handleNavigationHonorar() {
    //     setKapitalActive(false);
    //     setInnskuddActive(false);
    //     setBackupActive(false);
    //     setEierandelerActive(false);
    //     setHonorarActive(true);
    //     setHighlightColors(["#131316", "#131316", "#9233ff", "#131316", "#131316"]);
    //     setHighlightColor("#9233ff");

    //     setBackupExpanded(0);
    //     setMessage("");
    // }
    function handleNavigationInnskudd() {
        setKapitalActive(false);
        setEierandelerActive(false);
        setBackupActive(false);
        // setHonorarActive(false);
        setInnskuddActive(true);
        setHighlightColors(["#131316", "#131316", "#288bdb", "#131316"]);
        setHighlightColor("#288bdb");

        setBackupExpanded(0);
        setMessage("");
    }
    function handleNavigationBackup() {
        setKapitalActive(false);
        setEierandelerActive(false);
        setInnskuddActive(false);
        // setHonorarActive(false);
        setBackupActive(true);
        setHighlightColors(["#131316", "#131316", "#131316", "#ffce48"]);
        setHighlightColor("#ffce48");

        setBackupExpanded(0);
        setMessage("");
    }



    // handle transactions
    const [numberOfTransactions, setNumberOfTransactions] = useState(1);
    const newTransaction = () => {
        if (numberOfTransactions === 4) {
            setMessage('For øyeblikket går det ikke an å behandle mer enn 4 transaksjoner samtidig')
        }
        else {
            setMessage("");
            setNumberOfTransactions(numberOfTransactions + 1)
        }
    }
    const removeTransaction = () => {
        if (numberOfTransactions === 1) {
            setMessage('Det må minst være 1 transaksjon')
        }
        else {
            if (numberOfTransactions === 2) {
                setUser2Input('');
                setAmount2Input('');
                setType2Input('innskudd');
            } else if (numberOfTransactions === 3) {
                setUser3Input('');
                setAmount3Input('');
                setType3Input('innskudd');
            } else {
                setUser4Input('');
                setAmount4Input('');
                setType4Input('innskudd');
            }
            setMessage("");
            setNumberOfTransactions(numberOfTransactions - 1)
        }
    }




    // handle backup
    const [backupExpanded, setBackupExpanded] = useState(0);  // 0 = none, 1 = upload, 2 = download

    const handleUpload = () => {
        setBackupExpanded(1);
    }

    const handleDownload = () => {
        setBackupExpanded(2);
    }

    const cancel = () => {
        setBackupExpanded(0);
        setMessage("Du har avbrutt handlingen");
    }

    const upload = async() => {
        setMessage("");

        // upload copy of users
        const allUsers = await firestore.collection('users').get()
        allUsers.docs.forEach( async(user) => {
            let userData = user.data();
            await firestore.collection('usersBackup').doc(user.id).set({
                eierandel: userData.eierandel,
                innskudd: userData.innskudd, 
                name: userData.name,
                totaleInnskudd: userData.totaleInnskudd
            })
        })

        // upload copy of innskudd
        const allInnskudd = await firestore.collection('innskudd').get()
        allInnskudd.docs.forEach( async(innskudd) => {
            let innskuddData = innskudd.data();
            await firestore.collection('innskuddBackup').doc(innskudd.id).set({
                amount: innskuddData.amount,
                date: innskuddData.date, 
                type: innskuddData.type,
                userId: innskuddData.userId
            })
        })

        // upload copy of history
        const allHistory = await firestore.collection('history').get()
        allHistory.docs.forEach( async(history) => {
            let historyData = history.data();
            await firestore.collection('historyBackup').doc(history.id).set({
                value: historyData.value
            })
        })

        // upload copy of kurs
        const allKurs = await firestore.collection('kurs').get()
        allKurs.docs.forEach( async(kurs) => {
            let kursData = kurs.data();
            await firestore.collection('kursBackup').doc(kurs.id).set({
                value: kursData.value
            })
        })

        // upload copy of tables
        const allTables = await firestore.collection('tables').get()
        allTables.docs.forEach( async(table) => {
            let tableData = table.data();
            await firestore.collection('tablesBackup').doc(table.id).set({
                allUserIds: tableData.allUserIds,
                allNewEierandeler: tableData.allNewEierandeler,
                transactionUsers: tableData.transactionUsers,
                amounts: tableData.amounts,
                beholdningFør: tableData.beholdningFør,
                beholdningEtter: tableData.beholdningEtter
            })
        })

        // upload copy of master
        const master = (await firestore.collection('master').doc('master').get()).data();
        await firestore.collection('masterBackup').doc("master").set({
            admins: master.admins,
            date: master.date,
            totaleInnskudd: master.totaleInnskudd,
            verdi: master.verdi
        })
        
        setBackupExpanded(0);
        setMessage("Opplasting fullført");
    }

    const download = async() => {
        setMessage("");

        // download users from backup
        const allUsers = await firestore.collection('usersBackup').get()
        allUsers.docs.forEach( async(user) => {
            let userData = user.data();
            await firestore.collection('users').doc(user.id).set({
                eierandel: userData.eierandel,
                innskudd: userData.innskudd, 
                name: userData.name,
                totaleInnskudd: userData.totaleInnskudd
            })
        })

        // download innskudd from backup
        const allInnskudd = await firestore.collection('innskuddBackup').get()
        allInnskudd.docs.forEach( async(innskudd) => {
            let innskuddData = innskudd.data();
            await firestore.collection('innskudd').doc(innskudd.id).set({
                amount: innskuddData.amount,
                date: innskuddData.date, 
                type: innskuddData.type,
                userId: innskuddData.userId
            })
        })

        // download history from backup
        const allHistory = await firestore.collection('historyBackup').get()
        allHistory.docs.forEach( async(history) => {
            let historyData = history.data();
            await firestore.collection('history').doc(history.id).set({
                value: historyData.value
            })
        })

        // download kurs from backup
        const allKurs = await firestore.collection('kursBackup').get()
        allKurs.docs.forEach( async(kurs) => {
            let kursData = kurs.data();
            await firestore.collection('kurs').doc(kurs.id).set({
                value: kursData.value
            })
        })

        // download tables from backup
        const allTables = await firestore.collection('tablesBackup').get()
        allTables.docs.forEach( async(table) => {
            let tableData = table.data();
            await firestore.collection('tables').doc(table.id).set({
                allUserIds: tableData.allUserIds,
                allNewEierandeler: tableData.allNewEierandeler,
                transactionUsers: tableData.transactionUsers,
                amounts: tableData.amounts,
                beholdningFør: tableData.beholdningFør,
                beholdningEtter: tableData.beholdningEtter
            })
        })

        // download master from backup
        const master = (await firestore.collection('masterBackup').doc('master').get()).data();
        await firestore.collection('master').doc("master").set({
            admins: master.admins,
            date: master.date,
            totaleInnskudd: master.totaleInnskudd,
            verdi: master.verdi
        })

        getData();
        setBackupExpanded(0);
        setMessage("Innlasting fullført");
    }

    

    
    // errors, messages and signing out
    const [error, setError] = useState('');
    const [message, setMessage] = useState("");
    const { signOut } = useData();
    const history = useHistory();
    const { menuActivated } = useData();

    async function handleSignOut() {
        setError('');
        try {
            await signOut();
            history.push('/logg-inn');
        } catch {
            setError('Noe gikk galt, prøv igjen')
        }
    }




    // get data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [users, setUsers] = useState([]);

    const getData = async() => {
        setLoading(true);

        // get users
        const allUsers = await firestore.collection('users').get()
        const userItems = [];
        let eierandelerSum = 0;
        let cetaceaUser = [];
        let kursUser = [];
        allUsers.docs.forEach(user => {
            if (user.id === "cetacea") {
                cetaceaUser = [user.data(), user.id];
            }
            else if (user.id === "kurs") {
                kursUser = [user.data(), user.id];
            }
            else {
                userItems.push([user.data(), user.id]);
                eierandelerSum += user.data().eierandel;
            }
        })
        const mergedCetaceaUser = [
            {
                "name": "Cetacea AS",
                "eierandel": cetaceaUser[0].eierandel + kursUser[0].eierandel,
                "totaleInnskudd": cetaceaUser[0].totaleInnskudd + kursUser[0].totaleInnskudd,
            }, "cetacea"
        ]
        eierandelerSum += cetaceaUser[0].eierandel + kursUser[0].eierandel;
        userItems.push(mergedCetaceaUser);
        setUsers(userItems);
        setEierandelerSum(eierandelerSum);

        // get master data
        const master = (await firestore.collection('master').doc('master').get()).data();
        const cetacea = (await firestore.collection('users').doc('cetacea').get()).data();
        const kurs = (await firestore.collection('users').doc('kurs').get()).data();
        const vårEierandel = cetacea.eierandel + kurs.eierandel;
        const våreInnskudd = cetacea.totaleInnskudd + kurs.totaleInnskudd;
        const dataItems = {
            date: master.date,
            verdi: master.verdi,
            totaleInnskudd: master.totaleInnskudd,
            reellAvkastning: (((master.verdi - master.totaleInnskudd) / master.totaleInnskudd) * 100).toFixed(2),
            innskuttAksjekapital: cetacea.totaleInnskudd + kurs.totaleInnskudd,
            vårBeholdning: vårEierandel * master.verdi,
            vårAvkastning: ((((master.verdi * vårEierandel) - våreInnskudd) / våreInnskudd) * 100).toFixed(2),
            historiskAvkastning: ((((master.verdi * kurs.eierandel) - kurs.totaleInnskudd) / kurs.totaleInnskudd) * 100).toFixed(2)
        }
        setData(dataItems);

        setLoading(false);
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    



    // add/update data
    const updateValue = async() => {
        setLoading(true);
        setMessage("");
        if (valueInput && !isNaN(valueInput)) {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();
            var date = dd + '.' + mm + '.' + yyyy;
            
            await firestore.collection('master').doc('master').update('date', date);
            await firestore.collection('master').doc('master').update('verdi', +valueInput);

            await firestore.collection('history').doc(date).set({ value: +valueInput });

            const kursUser = (await firestore.collection('users').doc('kurs').get()).data();
            const newKurs = kursUser.eierandel * (+valueInput);
            await firestore.collection('kurs').doc(date).set({ value: +newKurs });

            getData();
            setMessage("Verdien ble oppdatert");
        } else { setMessage("Du må skrive inn et heltall"); }
        clearInputs();
        setLoading(false);
    }

    
    const addInnskudd = async() => {
        setLoading(true);
        setMessage("");

        // validation of inputs
        const userInputs = {
            ids: [user1Input, user2Input, user3Input, user4Input],
            amounts: [amount1Input, amount2Input, amount3Input, amount4Input],
            types: [type1Input, type2Input, type3Input, type4Input]
        }

        var userIds = [];  // all valid userIds
        var amounts = [];  // negative values if type="uttak"
        var types = [];

        for (let i = 0; i < 4; i++) {
            var amount = validateUser(i, userInputs)
            if (amount) { 
                userIds.push(userInputs.ids[i]);
                amounts.push(amount);
                types.push(userInputs.types[i]);
             }
        }

        const numberOfTransactions = userIds.length;

        if (numberOfTransactions > 0) {

            const nettoAmount = amounts.reduce((a, b) => a + b, 0)

            const validation = validateInputs(beholdningFørInput, beholdningEtterInput, dateInput, nettoAmount);

            if (validation) {  // validation completed

                // update master
                const master = (await firestore.collection("master").doc("master").get()).data()
                let totaleInnskudd = (+master.totaleInnskudd) + (+nettoAmount);
                firestore.collection("master").doc("master").update({
                    totaleInnskudd: totaleInnskudd,
                });

                // update all users' shares
                const allUsers = await firestore.collection('users').get()

                var allUserIds = [];
                var allNewEierandeler = [];
                
                allUsers.docs.forEach( async(user) => {
                    let currentUser = user.data();
                    let currentUserId = user.id;
                    let currentUserIndex = userIds.indexOf(currentUserId);

                    var transactionAmount = 0

                    if (currentUserIndex >= 0) {  // currentUser has made a new transaction

                        let userId = userIds[currentUserIndex];
                        let amount = amounts[currentUserIndex];
                        let type = types[currentUserIndex];

                        transactionAmount = amount;

                        // add transaction
                        firestore.collection('innskudd').add({
                            userId: userId,
                            amount: Math.abs(amount),
                            date: dateInput,
                            type: type
                        }).then( async(docRef) => {

                            // update user's transactions
                            const user = (await firestore.collection("users").doc(userId).get()).data()
                            const innskudd = user.innskudd;
                            innskudd.push(docRef.id);
                            let totaleInnskudd = (+user.totaleInnskudd) + (+amount);
                            firestore.collection("users").doc(userId).update({
                                innskudd: innskudd,
                                totaleInnskudd: totaleInnskudd,
                            });
                        });

                    } 

                    // recalcualte and update users' shares
                    let newEierandel = ((currentUser.eierandel * (+beholdningFørInput)) + transactionAmount) / (+beholdningEtterInput);
                    
                    allUserIds.push(user.id);
                    allNewEierandeler.push(newEierandel);

                    await firestore.collection('users').doc(currentUserId).update('eierandel', +newEierandel);
                })

                await firestore.collection('tables').doc(dateInput).set({
                    allUserIds: allUserIds,
                    allNewEierandeler: allNewEierandeler,
                    transactionUsers: userIds,
                    amounts: amounts,
                    beholdningFør: (+beholdningFørInput),
                    beholdningEtter: (+beholdningEtterInput)
                });

                getData();
                clearInputs();
                setMessage("Innskuddet ble lagt til og eierandelene ble oppdatert");

            } else {
                setMessage("Du har oppgitt ugyldige beløp eller ugyldig dato");
            }

        } else {
            setMessage("Du har oppgitt ugyldige brukere, beløp eller typer");
        }

        getData();
        setLoading(false);
    }

    const validateUser = (i, userInputs) => {
        let userId = userInputs.ids[i];
        let amount = userInputs.amounts[i];
        let type = userInputs.types[i];

        if ((userId.length === 28 || isNaN(userId)) && !isNaN(amount) && +amount > 0) {
            if (type === "innskudd") {
                return +amount
            } else if (type === "uttak") {
                return -1 * (+amount)
            } else {
                return false
            }
        }

        return false
    }

    const validateInputs = (beholdningFørInput, beholdningEtterInput, dateInput, nettoAmount) => {
        return (
            !isNaN(beholdningFørInput) && beholdningFørInput >= 0 &&
            !isNaN(beholdningEtterInput) && beholdningEtterInput > 0 &&
            dateInput.length === 10 && nettoAmount === (beholdningEtterInput - beholdningFørInput)
        );
    } 


    return (
        <div>
            {!menuActivated &&
            <div className="adminContainer">
                <div className="adminTopSection">
                    <div className="adminNavigation">
                        <button className="adminTab" onClick={handleNavigationKapital}>
                            Kapital
                        </button>
                        <button className="adminTab" onClick={handleNavigationEierandeler}>
                            Eierandeler
                        </button>
                        {/* <button className="adminTab" onClick={handleNavigationHonorar}>
                            Honorar
                        </button> */}
                        <button className="adminTab" onClick={handleNavigationInnskudd}>
                            Innskudd
                        </button>
                        <button className="adminTab" onClick={handleNavigationBackup}>
                            Backup
                        </button>
                    </div>
                    <div className="adminNavigationHighlight">
                        <div className="adminNavigationHighlightTab"
                        style={{ backgroundColor: highlightColors[0] }}>&nbsp;</div>
                        <div className="adminNavigationHighlightTab"
                        style={{ backgroundColor: highlightColors[1] }}>&nbsp;</div>
                        <div className="adminNavigationHighlightTab"
                        style={{ backgroundColor: highlightColors[2] }}>&nbsp;</div>
                        <div className="adminNavigationHighlightTab"
                        style={{ backgroundColor: highlightColors[3] }}>&nbsp;</div>
                        {/* <div className="adminNavigationHighlightTab"
                        style={{ backgroundColor: highlightColors[4] }}>&nbsp;</div> */}
                    </div>
                </div>
                <div className="adminTopLine" style={{ backgroundColor: highlightColor }}>
                    &nbsp;
                </div>

                {kapitalActive && 
                <div className="adminSection">
                    <div className="kapitalSection">
                        <div className="kapitalBox">
                            <div className="kapitalHeader">
                                Oppdater totalverdi 
                            </div>
                            <div className="kapitalFirstRow">
                                <input className="kapitalInput" onChange={handleValueChange} value={valueInput} />
                                <button disabled={loading} className="kapitalUpdateButton" onClick={updateValue}>
                                    Oppdater
                                </button>
                            </div>
                        </div>

                        <div className="kapitalBox">
                            <div className="kapitalHeader">
                                Historisk avkastning
                            </div>
                            <div className="kapitalSecondRow">
                                <div className="kapitalReturn">
                                    +{data["historiskAvkastning"]}%
                                </div>
                                <div className="kapitalReturnIcon">
                                    <InfoIcon style={{ fontSize: 30, color: "#ffffff", margin: "auto"}}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="kapitalSection">

                        <div className="kapitalBox">
                            <div className="kapitalHeader">
                                Beholdning
                            </div>
                            <div className="kapitalRow">
                                <div className="kapitalRowDescription">
                                    Total beholdning:
                                </div>
                                <div className="kapitalRowValue">
                                    { data["verdi"] }
                                </div>
                            </div>
                            <div className="kapitalRow">
                                <div className="kapitalRowDescription">
                                    Totale innskudd:
                                </div>
                                <div className="kapitalRowValue">
                                    { data["totaleInnskudd"] }
                                </div>
                            </div>
                            <div className="kapitalRow">
                                <div className="kapitalRowDescription">
                                    Reell avkastning:
                                </div>

                                { data.reellAvkastning >= 0 &&
                                <div className="kapitalRowValue">
                                    <div style={{ fontWeight: 500, color: "#19d46d" }}>
                                        +{data["reellAvkastning"]}%
                                    </div>
                                </div>}

                                { data["reellAvkastning"] < 0 &&
                                <div className="kapitalRowValue">
                                    <div style={{ fontWeight: 500, color: "#c91414" }}>
                                        {data["reellAvkastning"]}%
                                    </div>
                                </div>}

                            </div>
                        </div>

                        <div className="kapitalBox">
                            <div className="kapitalHeader">
                                Aksjekapital
                            </div>
                            <div className="kapitalRow">
                                <div className="kapitalRowDescription">
                                    Total aksjekapital:
                                </div>
                                <div className="kapitalRowValue">
                                    {Math.round(data["vårBeholdning"])},-
                                </div>
                            </div>
                            <div className="kapitalRow">
                                <div className="kapitalRowDescription">
                                    Innskutt aksjekapital: 
                                </div>
                                <div className="kapitalRowValue">
                                    { data["innskuttAksjekapital"] }
                                </div>
                            </div>
                            <div className="kapitalRow">
                                <div className="kapitalRowDescription">
                                    Vår avkastning:
                                </div>

                                { data.vårAvkastning >= 0 &&
                                <div className="kapitalRowValue">
                                    <div style={{ fontWeight: 500, color: "#19d46d" }}>
                                        +{data["vårAvkastning"]}%
                                    </div>
                                </div>}

                                { data.vårAvkastning < 0 &&
                                <div className="kapitalRowValue">
                                    <div style={{ fontWeight: 500, color: "#c91414" }}>
                                        {data["vårAvkastning"]}%
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>}

                {eierandelerActive &&
                <div className="adminSection">
                    <div className="eierandelerTable">
                        <div className="eierandelerFirstRow">    
                            <div className="eierandelerNavn">
                                Navn
                            </div>
                            <div className="eierandelerEierandel">
                                Eierandel
                            </div>
                            <div className="eierandelerBeholdning">
                                Beholdning
                            </div>
                            <div className="eierandelerInnskudd">
                                Innskudd
                            </div>
                            <div className="eierandelerAvkastningDesc">
                                Avkastning
                            </div>
                            <div className="eierandelerId">
                                ID
                            </div>
                        </div>

                        {!loading && users && users.map(user => {
                        return (
                            <div className="eierandelerRow">
                                <div className="eierandelerNavn">
                                    {user[0].name}
                                </div>
                                <div className="eierandelerEierandel">
                                    {(user[0].eierandel).toFixed(10)}
                                </div>
                                <div className="eierandelerBeholdning">
                                    {Math.round(user[0].eierandel * data.verdi)},-
                                </div>
                                <div className="eierandelerInnskudd">
                                    {user[0].totaleInnskudd},-
                                </div>
                                {(((user[0].eierandel*data.verdi)-user[0].totaleInnskudd)/user[0].totaleInnskudd)*100 >= 0 &&
                                <div className="eierandelerAvkastning">
                                    +{((((user[0].eierandel*data.verdi)-user[0].totaleInnskudd)/user[0].totaleInnskudd)*100).toFixed(2)}%
                                </div>}
                                {(((user[0].eierandel*data.verdi)-user[0].totaleInnskudd)/user[0].totaleInnskudd)*100 < 0 &&
                                <div className="eierandelerAvkastning" style={{ color: "#d11b67" }}>
                                    {((((user[0].eierandel*data.verdi)-user[0].totaleInnskudd)/user[0].totaleInnskudd)*100).toFixed(2)}%
                                </div>}
                                <div className="eierandelerId">
                                    {user[1]}
                                </div>
                            </div>
                        )})}

                    </div>
                </div>}

                {/* {honorarActive &&
                <div className="adminSection">
                    <div className="honorarTitle">
                        Her er alle forvaltningshonorarene fra første periode (01.05.2021 - 31.12.2021), 
                        og alle tallene gjelder for denne perioden. 
                    </div>
                    <div className="honorarTable">
                        <div className="honorarFirstRow">    
                            <div className="honorarNavn">
                                Navn
                            </div>
                            <div className="honorarAvkastning">
                                Avkastning
                            </div>
                            <div className="honorarHonorarProsent">
                                Honorar (%)
                            </div>
                            <div className="honorarHonorarBeløp">
                                Honorar (kr)
                            </div>
                            <div className="honorarBeholdningFør">
                                Beholdning før
                            </div>
                            <div className="honorarBeholdningEtter">
                                Beholdning etter
                            </div>
                        </div>

                        {!loading && users && users.map(user => {
                        return (
                            <div className="honorarRow">
                                <div className="honorarNavn">
                                    {user[0].name}
                                </div>
                                <div className="honorarAvkastning">
                                    13
                                </div>
                                <div className="honorarHonorarProsent">
                                    13
                                </div>
                                <div className="honorarHonorarBeløp">
                                    12345
                                </div>
                                <div className="honorarBeholdningFør">
                                    123456
                                </div>
                                <div className="honorarBeholdningEtter">
                                    1234567
                                </div>
                            </div>
                        )})}

                    </div>
                </div>} */}

                {innskuddActive &&
                <div className="adminSection">
                    <div className="innskuddSection">
                        <div className="addInnskuddBox">
                            <div className="addInnskuddHeader">
                                Nye innskudd eller uttak
                            </div>
                            <div className="addInnskuddRow">
                                <div className="addInnskuddEntry">
                                    Beholdning før
                                </div>
                                <div className="addInnskuddEntry">
                                    Beholdning etter
                                </div>
                                <div className="addInnskuddEntry">
                                    Dato
                                </div>
                            </div>
                            <div className="addInnskuddRow">
                                <div className="addInnskuddEntry">
                                    <input className="addInnskuddInput" onChange={handleBeholdningFørChange} value={beholdningFørInput} />
                                </div>
                                <div className="addInnskuddEntry">
                                    <input className="addInnskuddInput" onChange={handleBeholdningEtterChange} value={beholdningEtterInput} />
                                </div>
                                <div className="addInnskuddEntry">
                                    <input className="addInnskuddInput" onChange={handleDateChange} value={dateInput} />
                                </div>
                                <button disabled={loading} className="addInnskuddButton" onClick={addInnskudd}>
                                    Send inn
                                </button>
                            </div>

                            <div className="addInnskuddRow">
                                <div className="addInnskuddUser">
                                    Bruker
                                </div>
                                <div className="addInnskuddEntry">
                                    Beløp
                                </div>
                                <div className="addInnskuddEntry">
                                    Type
                                </div>
                            </div>
                            <div className="addInnskuddRow">
                                <div className="addInnskuddUser">
                                    <input className="addInnskuddUserInput" onChange={handleUser1Change} value={user1Input} />
                                </div>
                                <div className="addInnskuddEntry">
                                    <input className="addInnskuddInput" onChange={handleAmount1Change} value={amount1Input} />
                                </div>
                                <div className="addInnskuddEntry">
                                    <input className="addInnskuddInput" onChange={handleType1Change} value={type1Input} />
                                </div>
                            </div>

                            {numberOfTransactions > 1 &&
                            <div>
                                <div className="addInnskuddRow">
                                    <div className="addInnskuddUser">
                                        Bruker
                                    </div>
                                    <div className="addInnskuddEntry">
                                        Beløp
                                    </div>
                                    <div className="addInnskuddEntry">
                                        Type
                                    </div>
                                </div>
                                <div className="addInnskuddRow">
                                    <div className="addInnskuddUser">
                                        <input className="addInnskuddUserInput" onChange={handleUser2Change} value={user2Input} />
                                    </div>
                                    <div className="addInnskuddEntry">
                                        <input className="addInnskuddInput" onChange={handleAmount2Change} value={amount2Input} />
                                    </div>
                                    <div className="addInnskuddEntry">
                                        <input className="addInnskuddInput" onChange={handleType2Change} value={type2Input} />
                                    </div>
                                </div>
                            </div>}

                            {numberOfTransactions > 2 &&
                            <div>
                                <div className="addInnskuddRow">
                                    <div className="addInnskuddUser">
                                        Bruker
                                    </div>
                                    <div className="addInnskuddEntry">
                                        Beløp
                                    </div>
                                    <div className="addInnskuddEntry">
                                        Type
                                    </div>
                                </div>
                                <div className="addInnskuddRow">
                                    <div className="addInnskuddUser">
                                        <input className="addInnskuddUserInput" onChange={handleUser3Change} value={user3Input} />
                                    </div>
                                    <div className="addInnskuddEntry">
                                        <input className="addInnskuddInput" onChange={handleAmount3Change} value={amount3Input} />
                                    </div>
                                    <div className="addInnskuddEntry">
                                        <input className="addInnskuddInput" onChange={handleType3Change} value={type3Input} />
                                    </div>
                                </div>
                            </div>}

                            {numberOfTransactions > 3 &&
                            <div>
                                <div className="addInnskuddRow">
                                    <div className="addInnskuddUser">
                                        Bruker
                                    </div>
                                    <div className="addInnskuddEntry">
                                        Beløp
                                    </div>
                                    <div className="addInnskuddEntry">
                                        Type
                                    </div>
                                </div>
                                <div className="addInnskuddRow">
                                    <div className="addInnskuddUser">
                                        <input className="addInnskuddUserInput" onChange={handleUser4Change} value={user4Input} />
                                    </div>
                                    <div className="addInnskuddEntry">
                                        <input className="addInnskuddInput" onChange={handleAmount4Change} value={amount4Input} />
                                    </div>
                                    <div className="addInnskuddEntry">
                                        <input className="addInnskuddInput" onChange={handleType4Change} value={type4Input} />
                                    </div>
                                </div>
                            </div>}

                            <div className="addInnskuddLastRow">
                                <div className="addTransactionButton" onClick={newTransaction}>
                                    <AddCircleIcon style={{ fontSize: 40, color: "#ffffff" }}/>
                                </div>
                                <div className="removeTransactionButton" onClick={removeTransaction}>
                                    <RemoveCircleIcon style={{ fontSize: 40, color: "#ffffff" }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                {backupActive &&
                <div className="adminSection">
                    <div className="backupSection">
                        <div className="backupBox">
                            <div className="backupHeader">
                                Sikkerhetskopi av databasen
                            </div>
                            <div className="backupRow">
                                <div className="backupColumn">
                                    <div className="backupDesc">
                                        Last opp data
                                    </div>
                                    <div className="backupButton" onClick={handleUpload}>
                                        <BackupIcon style={{ fontSize: 50, color: "#ffffff" }}/>
                                    </div>
                                </div>
                                <div className="backupColumn">
                                    <div className="backupDesc">
                                        Last inn data
                                    </div>
                                    <div className="backupButton" onClick={handleDownload}>
                                        <CloudDownloadIcon style={{ fontSize: 50, color: "#ffffff" }}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {backupExpanded === 1 && 
                        <div className="backupBox">
                            <div className="backupHeader">
                                Er du sikker på at du vil laste opp en sikkerhetskopi?
                            </div>
                            <div className="backupWarning">
                                <WarningIcon style={{ fontSize: 44, marginTop: -12, color: "#d11b67" }}/>
                                Overskriver sikkerhetskopi
                                <WarningIcon style={{ fontSize: 44, marginTop: -12, marginLeft: 3, color: "#d11b67" }}/>
                            </div>
                            <div className="backupSecondRow">
                                <div className="backupColumn">
                                    <button disabled={loading} className="backupConfirmButton" onClick={upload}>
                                        Ja, last opp!
                                    </button>
                                </div>
                                <div className="backupColumn">
                                    <button disabled={loading} className="backupCancelButton" onClick={cancel}>
                                        Nei, avbryt!
                                    </button>
                                </div>
                            </div>
                        </div>}

                        {backupExpanded === 2 && 
                        <div className="backupBox">
                            <div className="backupHeader">
                                Er du sikker på at du vil laste inn en sikkerhetskopi?
                            </div>
                            <div className="backupWarning">
                                <WarningIcon style={{ fontSize: 44, marginTop: -12, color: "#d11b67" }}/>
                                Overskriver gjeldende data
                                <WarningIcon style={{ fontSize: 44, marginTop: -12, marginLeft: 3, color: "#d11b67" }}/>
                            </div>
                            <div className="backupSecondRow">
                                <div className="backupColumn">
                                    <button disabled={loading} className="backupConfirmButton" onClick={download}>
                                        Ja, last inn!
                                    </button>
                                </div>
                                <div className="backupColumn">
                                    <button disabled={loading} className="backupCancelButton" onClick={cancel}>
                                        Nei, avbryt!
                                    </button>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>}

                {error && <div className="danger">
                    <div className="dangerSymbol">
                        <ErrorIcon style={{ fontSize: 30, color: "#FFFFFF" }}/>
                    </div>
                    {error}
                </div>}
                
                {!error && <div className="empty">&nbsp;</div>}

                <div className="adminMessage">{message}</div>

                <div className="adminLogo">
                    Oppdatert: {data.date}
                </div>

                <button className="adminSignOut" onClick={handleSignOut}>Logg ut</button>

            </div>}
        </div>
    );
}
