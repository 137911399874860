import React, { useContext, useState, useEffect, useLayoutEffect } from 'react';
import { auth } from '../Firebase';

const Context = React.createContext();

export function useData() {
    return useContext(Context);
}

export function ContextProvider({ children }) {

    const [menuActivated, setMenuActivated] = useState();

    function toggleMenu() {
        setMenuActivated(!menuActivated);
    }

    function closeMenu() {
        setMenuActivated(false);
    }


    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    function signUp(email, password) {
        return auth.createUserWithEmailAndPassword(email, password);
    }

    function signIn(email, password) {
        return auth.signInWithEmailAndPassword(email, password);
    }

    function signOut() {
        return auth.signOut();
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email);
    }


    function useMediaQuery() {
        const [screenSize, setScreenSize] = useState([0, 0]);
        
        useLayoutEffect(() => {
            function updateScreenSize() {
                setScreenSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener("resize", updateScreenSize);
            updateScreenSize();
            return () => window.removeEventListener("resize", updateScreenSize);
        }, []);

        return screenSize;
    }


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            setLoading(false);
        });

        setMenuActivated(false);

        return unsubscribe;
    }, []);

    const value = {
        menuActivated,
        toggleMenu,
        closeMenu,
        useMediaQuery,
        currentUser,
        signUp,
        signIn,
        signOut,
        resetPassword
    }
 
    return (
        <Context.Provider value={value}>
            {!loading && children}
        </Context.Provider>
    );
}
