import React from 'react';
import '../index.css';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';


export default function Footer() {
    
    function handlePageChangeCasper() {
        window.location.href="https://www.linkedin.com/in/casper-svendsen-a1277a1a1/";
    }

    function handlePageChangeEliot() {
        window.location.href="https://www.linkedin.com/in/eliot-strobel-3552b3130/";
    }

    return (
        <div className="footer">
            <div className="footerCompany">
                <div className="footerCompanyRow">
                    <div className="footerSection">
                        <div className="companyText">
                            Cetacea AS
                        </div>
                    </div>
                </div>
                <div className="footerCompanyRow">
                    <div className="footerSection">
                        <div className="footerSymbol">
                            <EmailIcon style={{ fontSize: 20 }}/>
                        </div>
                        <div className="footerText">
                            cetaceaholding@gmail.com
                        </div>
                    </div>
                </div>
            </div>
            <div className="footerDivider">&nbsp;</div>
            <div className="footerMembers">
                <div className="footerMember">
                    <div className="footerSection">
                        <div className="footerSymbol">
                            <LinkedInIcon style={{ fontSize: 20 }}/>
                        </div>
                        <div className="footerText">
                            Torstein Horn
                        </div>
                    </div>
                    <div className="footerSection">
                        <div className="footerSymbol">
                            <PhoneIcon style={{ fontSize: 20 }}/>
                        </div>
                        <div className="footerPlus">
                            +
                        </div>
                        <div className="footerText">
                            47 412 09 622
                        </div>
                    </div>
                    <div className="footerSection">
                        <div className="footerSymbol">
                            <EmailIcon style={{ fontSize: 20 }}/>
                        </div>
                        <div className="footerText">
                            tottehorn@gmail.com
                        </div>
                    </div>
                </div>
                <div className="footerLine">&nbsp;</div>
                <div className="footerMember">
                    <div className="footerSection">
                        <div className="footerSymbol">
                            <LinkedInIcon style={{ fontSize: 20 }}/>
                        </div>
                        <div className="footerText">
                            Emil Haug
                        </div>
                    </div>
                    <div className="footerSection">
                        <div className="footerSymbol">
                            <PhoneIcon style={{ fontSize: 20 }}/>
                        </div>
                        <div className="footerPlus">
                            +
                        </div>
                        <div className="footerText">
                            47 476 73 512
                        </div>
                    </div>
                    <div className="footerSection">
                        <div className="footerSymbol">
                            <EmailIcon style={{ fontSize: 20 }}/>
                        </div>
                        <div className="footerText">
                            emil.andreas.haug@gmail.com
                        </div>
                    </div>
                </div>
                <div className="footerLine">&nbsp;</div>
                <div className="footerMember">
                    <div className="footerSection">
                        <div className="footerSymbol" onClick={handlePageChangeCasper}>
                            <LinkedInIcon style={{ fontSize: 20 }}/>
                        </div>
                        <div className="footerText">
                            Casper Svendsen
                        </div>
                    </div>
                    <div className="footerSection">
                        <div className="footerSymbol">
                            <PhoneIcon style={{ fontSize: 20 }}/>
                        </div>
                        <div className="footerPlus">
                            +
                        </div>
                        <div className="footerText">
                            47 953 31 488
                        </div>
                    </div>
                    <div className="footerSection">
                        <div className="footerSymbol">
                            <EmailIcon style={{ fontSize: 20 }}/>
                        </div>
                        <div className="footerText">
                            casp.svendsen@gmail.com
                        </div>
                    </div>
                </div>
                <div className="footerLine">&nbsp;</div>
                <div className="footerMember">
                    <div className="footerSection">
                        <div className="footerSymbol" onClick={handlePageChangeEliot}>
                            <LinkedInIcon style={{ fontSize: 20 }}/>
                        </div>
                        <div className="footerText">
                            Eliot Strobel
                        </div>
                    </div>
                    <div className="footerSection">
                        <div className="footerSymbol">
                            <PhoneIcon style={{ fontSize: 20 }}/>
                        </div>
                        <div className="footerPlus">
                            +
                        </div>
                        <div className="footerText">
                            47 909 19 885
                        </div>
                    </div>
                    <div className="footerSection">
                        <div className="footerSymbol">
                            <EmailIcon style={{ fontSize: 20 }}/>
                        </div>
                        <div className="footerText">
                            eliot.strobel@gmail.com
                        </div>
                    </div>
                </div>
            </div>
            <div className="footerSmallSpace">&nbsp;</div>
        </div>
    );
}