import React from 'react';
import '../index.css';
import Footer from '../components/Footer';
import SignIn from '../components/SignIn';
import { useData } from '../contexts/Context';


export default function LoggInn() {

    const { menuActivated } = useData();

    return (
        <div>
            {!menuActivated &&
            <div className="viewContainer">
                <div className="loggInnBackground">&nbsp;</div>
                <SignIn />
                <Footer />
            </div>}
        </div>
    );
}
