import React from 'react';
import '../index.css';
import Footer from '../components/Footer';
import { useData } from '../contexts/Context';


export default function OmOss() {

    const { menuActivated } = useData();

    return (
        <div>
            {!menuActivated && <div className="viewContainer">
                <div className="omOssImage">
                    <div className="omOssImageTitle">
                        Om oss
                    </div>
                    <div className="omOssImageLine">&nbsp;</div>
                    <div className="omOssImageSubTitle">
                        Her kan du bli kjent med investeringsteamet i Cetacea
                    </div>
                </div>
                <div className="omOssFirstSection">
                    <div className="omOssUnderUtvikling">
                        Mer informasjon kommer.
                    </div>
                </div>
                <Footer />
            </div>}
        </div>
    );
}
