import React from 'react';
import Footer from '../components/Footer';
import '../index.css';
import { Link } from 'react-router-dom';
import SvgKjopFondUp from '../svg/SvgKjopFondUp';
import SvgKjopFondDown from '../svg/SvgKjopFondDown';
import { useData } from '../contexts/Context';


export default function KjopFond() {

    const { menuActivated } = useData();

    return (
        <div>
            {!menuActivated &&
            <div className="viewContainer">
                   <div className="kjopFondImage">
                    <div className="kjopFondImageTitle">
                        Kjøp og selg fond
                    </div>
                    <div className="kjopFondImageLine">&nbsp;</div>
                    <div className="kjopFondImageSubTitle">
                        Vi jobber for tiden med å få på plass en betalingsløsning inne på denne siden.
                    </div>
                </div>
                <div className="kjopFondSection">
                    <div className="kjopFondIntroHeader">
                        Hos oss er det enkelt å kjøpe og selge fond
                    </div>
                    <div className="kjopFondIntroText">
                        Alt du trenger å gjøre er å&nbsp;
                        <Link to='/kontakt' className="kjopFondSelgKontaktLink">kontakte oss</Link> 
                        &nbsp;og fortelle hvor mye du ønsker å kjøpe eller selge, og så
                        tar vi av oss resten. Under finner du ytterligere informasjon om kjøp og salg av fond.
                    </div>
                    <div className="kjopFondKjopSection">
                        <SvgKjopFondDown className="kjopFondSvg" color1="#131316" color2="#FFFFFF" />
                        <div className="kjopFondKjopHeader">
                            Kjøp fond
                        </div>
                        <div className="kjopFondKjopText">
                            Dersom du ønsker å kjøpe fond i Cetacea, er det bare å&nbsp;
                            <Link to='/kontakt' className="kjopFondKjopKontaktLink">kontakte oss</Link>
                            &nbsp;og fortelle hvor mye du ønsker å investere. 
                            Deretter vil vi kontakte deg tilbake med ytterligere informasjon.
                        </div>
                        <div className="kjopFondKjopText">
                            Vi har ingen grenser for innskudd, og vi har heller ingen tidsfrister du trenger å forholde deg til. 
                            Det betyr at du kan investere akkurat så mye du ønsker, akkurat når du ønsker.
                        </div>
                    </div>
                    <div className="kjopFondSelgSection">
                        <SvgKjopFondUp className="kjopFondSvg" color1="#131316" color2="#FFFFFF" />
                        <div className="kjopFondSelgHeader">
                            Selg fond
                        </div>
                        <div className="kjopFondSelgText">
                            Dersom du ønsker å selge fond i Cetacea, er det bare å&nbsp;
                            <Link to='/kontakt' className="kjopFondSelgKontaktLink">kontakte oss</Link>
                            &nbsp;og fortelle hvor mye av din beholdning du ønsker å selge.
                            Deretter vil vi kontakte deg tilbake med ytterligere informasjon.
                        </div>
                        <div className="kjopFondSelgText">
                            Som kunde hos oss kan du maksimalt ta ut penger 4 ganger i løpet av et kalenderår. For hvert uttak vil det normalt ta
                            1-3 virkedager før pengene er overført til deg, men ved ekstraordinære omstendigheter forbeholder vi oss retten til 
                            å bruke opptil 10 handelsdager på å fullføre transaksjonen. 
                        </div>
                        <div className="kjopFondSpace">&nbsp;</div>
                        <SvgKjopFondDown className="kjopFondSvg" color1="#131316" color2="#FFFFFF" />
                    </div>
                </div>
                <Footer />
            </div>}
        </div>
    );
}